import React from 'react';

import Layout from '../components/layout/Layout';
import CardHeading from '../components/layout/CardHeading';

const heading = {
  title: 'Thank You!',
  desc: 'We have received your message, and will get back to you as soon as possible!',
  btnName: 'Ok',
  btnPath: '/',
};

const ThankYou = () => (
  <Layout>
    <div className="thank-you">
      <CardHeading heading={heading} />
    </div>
  </Layout>
);

export default ThankYou;
